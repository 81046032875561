import thunkMiddleware, { ThunkDispatch, ThunkAction } from "redux-thunk";
import { createStore, combineReducers, applyMiddleware, AnyAction, Action } from "redux";
import DataTableReducer from "./reducers/DataTableReducer";
import AppReducer from "./reducers/AppReducer";
import { useDispatch } from "react-redux";

const RootReducer = combineReducers({
    appState: AppReducer,
    dataTableState: DataTableReducer
});

export type RootState = ReturnType<typeof RootReducer>
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>
export type AppThunkAction<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
export const useThunkDispatch = () => useDispatch<AppThunkDispatch>()
export default (initialState?: RootState) => createStore(RootReducer, initialState, applyMiddleware(thunkMiddleware));