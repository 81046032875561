import Request from '../../utils/Request';
import { Dispatch } from 'redux';
import { APP } from '../ActionTypes';
import { RootState } from '../store';
import config from '../../config';
import { ModalName } from '../../utils/ModalManager';
import Cookie from "../../utils/Cookie";
import axios from "axios";

const login = (params: { email: String, password: String }) => {
    return async function (dispatch: Dispatch, getState: () => RootState) {
        const response = await Request(getState()).post(process.env.API_ENDPOINT + "/login", params);
        dispatch({ type: APP.LOGIN_SUCCESS, result: response.data });
        return response;
    };
}

const authorize = (token: string) => {
    return async function (dispatch: Dispatch, getState: () => RootState) {
        try {
            const response = await Request(getState()).get(process.env.API_ENDPOINT + "/profile", {
                headers: { 'Authorization': token }
            });
            const employee = response.data.employee;
            dispatch({ type: APP.AUTHORIZED, result: { token, employee } });
            return response;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                Cookie.erase("token");
            } else {
                console.error(error);
                throw error;
            }
        }
    };
}

const switchModal = (name: ModalName | null, data?: any) => {
    return { type: APP.MODAL_CHANGED, name, data }
}

const setMessage = (payload?: { text: string, isError: boolean, milliseconds: number }) => {
    return { type: APP.REPLACE_MESSAGE, payload };
}

const ping = (params: any) => {
    return async function (dispatch: Dispatch, getState: () => RootState) {
        if (getState().appState.token) {
            const response = await Request(getState()).get(process.env.API_ENDPOINT + "/ping", params);
            dispatch({ type: APP.PONG, payload: { notifications: response.data.notifications, status: response.data.status } });
            const settings = response.data.settings;
            const currentVersion = config.VERSION;
            if (settings.version.min > currentVersion) {
                location.reload();
            } else if (settings.version.latest > currentVersion) {
                dispatch(setMessage({ text: "New Version Available. Please refresh the page to update.", isError: true, milliseconds: 30000 }));
            }
            return response;
        }
    };
}

export default { login, authorize, switchModal, ping, setMessage };