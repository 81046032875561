import React from "react";
import LoadingIcon from "../icons/LoadingIcon";

const LoadingPage = () => {
    return (
        <div className="p-9 flex justify-center">
            <div className="text-center">
                <LoadingIcon />
                <div className="text-lg">Loading Page</div>
            </div>
        </div>
    );
}

export default LoadingPage;