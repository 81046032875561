import React, { useEffect } from 'react';
import routes from './routes';
import Private from './utils/Private';
import { useDispatch } from 'react-redux';
import AppAction from './store/actions/AppAction';
import { Switch, Route, useHistory } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';

// const registerServiceWorker = async () => {
//     if ('serviceWorker' in navigator && process.env.NODE_ENV == 'production') {
//         try {
//             const registration = await navigator.serviceWorker.register('sw.js');
//             console.log('Hooray. Registration successful, scope is:', registration.scope);
//         } catch (error) {
//             console.log('Whoops. Service worker registration failed, error:', error);
//         }
//     }
// }

const App = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const ping = () => dispatch(AppAction.ping({}));
    useEffect(() => {
        // registerServiceWorker();
        ping();
        const unregisterHistory = history.listen((location) => document.body.scrollTo(0, 0));
        const intervalId = setInterval(ping, 60 * 1000);
        return () => {
            clearInterval(intervalId);
            unregisterHistory();
        };
    }, []);

    return (
        <MantineProvider>
            <Switch>
                {routes.map(route => {
                    const component = route.isPrivate ? Private(route.component) : route.component;
                    return <Route key={route.name} path={route.path} component={component} exact={route.exact} />
                })}
            </Switch>
        </MantineProvider>
    );
}

export default App;