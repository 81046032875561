import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import createStore from "./store/store";

const store = createStore((window as any).__PRELOADED_STATE__);
delete (window as any).__PRELOADED_STATE__;

const rootElement = document.getElementById("app");
if (!rootElement) {
    throw new Error("Root container with name app not found");
}
const root = createRoot(rootElement);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);
