import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import config from '../config';
import { RootState } from '../store/store';

export default function Request(state: RootState): AxiosInstance {
    const axiosConfig: AxiosRequestConfig = {
        headers: { Version: config.VERSION }
    };
    if (state.appState.token) {
        axiosConfig.headers['Authorization'] = state.appState.token;
    }
    return axios.create(axiosConfig);
}