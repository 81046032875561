import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Redirect, useHistory } from 'react-router';
import Cookie from "./Cookie";
import { RootState, useThunkDispatch } from "../store/store";
import AppAction from "../store/actions/AppAction";
import LoadingIcon from "../icons/LoadingIcon";

const mapRootState = (state: RootState) => {
    return { token: state.appState.token };
}

const Private = (Component: React.ComponentType<any>) => {

    return (props: any) => {
        const history = useHistory();
        const { token } = useSelector(mapRootState);
        const dispatch = useThunkDispatch();
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState<string>();

        const authorize = useCallback(async (token: string) => {
            try {
                setError(undefined);
                setLoading(true);
                await dispatch(AppAction.authorize(token));
            } catch (error) {
                let message = "An unknown error occurred";
                if (error instanceof Error) {
                    message = error.message;
                }
                setError(message);
            }
            setLoading(false);
        }, []);

        useEffect(() => {
            if (!token) {
                const cookieToken = Cookie.get("token");
                if (cookieToken) {
                    authorize(cookieToken);
                }
            }
        }, [token]);

        if (token) {
            return (<Component {...props} />);
        }
        if (!token && !Cookie.get("token") && !loading) {
            const path = history.location.pathname + history.location.search;
            return <Redirect to={`/login?path=${path}`} />
        }

        if (error && !loading) {
            return (
                <div className="flex justify-center mt-xl">
                    <p className="bg-red white p-xl pv-lg bold">{error}</p>
                </div>
            );
        }

        return (
            <div className="flex justify-center">
                <LoadingIcon />
            </div>
        );
    };
}

export default Private;