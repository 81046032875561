import { APP } from "../ActionTypes";
import Cookie from "../../utils/Cookie";
import Employee from "../../types/Employee";
import { ModalName } from "../../utils/ModalManager";

export interface AppState {
    token?: string,
    employee?: Employee,
    notifications?: [],
    status?: any,
    modal?: {
        name: ModalName,
        data: any
    },
    message?: {
        text: string,
        isError: boolean,
        milliseconds: number
    }
}

const initialState: AppState = {};

const modalReducer = (state: AppState, action: any): AppState => {
    return {
        ...state, modal: {
            name: action.name,
            data: action.data || {}
        }
    };
}

const loginReducer = (state: AppState, action: any): AppState => {
    const token = action.result.token;
    Cookie.set("token", token, 7);
    return { ...state, token, employee: action.result.employee };
}

const authorizedReducer = (state: AppState, action: any): AppState => {
    return { ...state, token: action.result.token, employee: action.result.employee };
}

const setMessage = (state: AppState, action: any) => {
    const message = action.payload;
    return { ...state, message };
}

const pong = (state: AppState, action: any) => {
    const { notifications, status } = action.payload;
    return { ...state, notifications, status };
}

export default (state: AppState = initialState, action: any): AppState => {
    const map: { [key: string]: (state: AppState, action: any) => AppState } = {
        [APP.MODAL_CHANGED]: modalReducer,
        [APP.LOGIN_SUCCESS]: loginReducer,
        [APP.AUTHORIZED]: authorizedReducer,
        [APP.REPLACE_MESSAGE]: setMessage,
        [APP.PONG]: pong
    };
    const reducer = map[action.type];
    return reducer ? reducer(state, action) : state || initialState;
}