export enum APP {
    AUTHORIZED = "authorized",
    MODAL_CHANGED = "app_modal_changed",
    REPLACE_MESSAGE = "app_replace_message",
    LOGIN_SUCCESS = "app_login_success",
    NOTIFICATIONS = "app_notifications",
    PONG = "app_pong"
}

export enum DATA_TABLE {
    LOADING = "data_table_loading",
    LOADED = "data_table_loaded",
    FAILED = "data_table_failed"
}

export enum BACKORDER_OPTION {
    UPDATED = "option_updated"
}

export enum AUCTION {
    UPDATED = "auction_updated"
}

export enum BATCH {
    UPDATED = "batch_updated"
}

export enum SCAN {
    UPDATED = "scan_updated"
}

export enum ORDER {
    UPDATED = "order_updated"
}

export enum DOMAIN {
    UPDATED = "domain_updated"
}