import { DATA_TABLE, AUCTION, ORDER, BACKORDER_OPTION, BATCH, DOMAIN, SCAN } from "../ActionTypes";

interface Table {
    key: string,
    meta: any,
    filters: any,
    urlParams: any,
    list: any[] | null,
    isLoading: boolean,
}

type DataTableState = Record<string, Table | undefined>

const initialState: DataTableState = {};

const updateTable = (state: any, action: any) => {
    if (state && state.list) {
        const newState = {
            ...state,
            list: state.list.map((item: any) => {
                if (action.type === DOMAIN.UPDATED && item.domain?.id === action.item.id) {
                    return { ...item, domain: { ...item.domain, ...action.item } };
                }
                if (action.type !== DOMAIN.UPDATED && item.id === action.item.id) {
                    return { ...item, ...action.item }
                };
                return item;
            })
        };
        return newState;
    }
    return state;
}

const loadingReducer = (state: DataTableState, action: any): DataTableState => {
    const name: string = action.name;
    return {
        ...state,
        [name]: {
            key: action.key,
            isLoading: true,
            list: null,
            meta: {},
            filters: action.params,
            urlParams: action.params,
        }
    };
}

const loadedReducer = (state: DataTableState, action: any): DataTableState => {
    const name: string = action.name;
    if (state[name]?.key == action.key) {
        return {
            ...state,
            [action.name]: {
                ...state[name],
                key: action.key,
                isLoading: false,
                list: action.result.data,
                meta: action.result.meta,
                filters: action.result.filters,
            }
        };
    }
    return state;
}

const updateItemReducer = (state: DataTableState, action: any): DataTableState => {
    const map: Record<string, string[]> = {
        [BACKORDER_OPTION.UPDATED]: ['backorder-options'],
        [AUCTION.UPDATED]: ['auctions'],
        [BATCH.UPDATED]: ['batches'],
        [ORDER.UPDATED]: ['orders', 'portfolio'],
        [DOMAIN.UPDATED]: ["backorder-options", "auctions"],
        [SCAN.UPDATED]: ["scans"],
    };
    const names = map[action.type];
    let patches = {};
    for (const name of names) {
        if (name && state[name]) {
            patches = { ...patches, [name]: updateTable(state[name], action) };
        }
    }
    return { ...state, ...patches };
}


export default (state: DataTableState = initialState, action: any): DataTableState => {
    const map: Record<string, (state: DataTableState, action: any) => DataTableState> = {
        [DATA_TABLE.LOADING]: loadingReducer,
        [DATA_TABLE.LOADED]: loadedReducer,
        [BACKORDER_OPTION.UPDATED]: updateItemReducer,
        [AUCTION.UPDATED]: updateItemReducer,
        [BATCH.UPDATED]: updateItemReducer,
        [ORDER.UPDATED]: updateItemReducer,
        [DOMAIN.UPDATED]: updateItemReducer,
        [SCAN.UPDATED]: updateItemReducer
    };
    const reducer = map[action.type];
    return reducer ? reducer(state, action) : state || initialState;
}